import {ar, enUS, es, hi, ja, ko, ru, uk, zhCN} from "date-fns/locale";

export const getCurrencyByCountry = (country, availableCurrencies, defaultCurrency) => {
  const currency = Object.values(availableCurrencies).find((cur) =>
    cur.countries.includes(country)
  );

  return currency || defaultCurrency;
};

export const getLanguageByCountry = (countryCode, availableLanguages, defaultLanguage) => {
  const language = Object.values(availableLanguages).find((lang) =>
    lang.countries.includes(countryCode)
  );

  return language || defaultLanguage;
};

export const locales = {enUS, ar, hi, es, ru, uk, zhCN, ja, ko};