import {createSlice} from '@reduxjs/toolkit';
import {localStorageService} from "../../services/localStorageService";
import {CONSTANTS} from "../../constants/constants";

const initialState = {
  isAuth: !!localStorageService?.get(CONSTANTS?.VALUE?.ACCESS_TOKEN) ?? false,
  country: null,
  currency: null,
  language: null,
  dataPeople: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuth(state, action) {
      state.isAuth = action.payload;
    },
    setCurrency(state, action) {
      const {currency} = action.payload;
      state.currency = currency;
    },
    setLanguage(state, action) {
      const {language} = action.payload;
      state.language = language;
    },
    setDataPeople(state, action) {
      const {dataPeople} = action.payload;
      state.dataPeople = dataPeople;
    },
    setCountry(state, action) {
      const {country} = action.payload;
      state.country = country;
    },
    resetUser(state) {
      state.dataPeople = initialState.dataPeople;
    },
  }
});

export const {
  setIsAuth,
  setCurrency,
  setLanguage,
  setDataPeople,
  setCountry,
  resetUser,
} = userSlice.actions;

export default userSlice.reducer;