import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  phoneNumber: '',
  activeSlide: 0,
  lastResendTime: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    setActiveSlide(state, action) {
      state.activeSlide = action.payload;
    },
    setLastResendTime(state, action) {
      state.lastResendTime = action.payload;
    },
    resetAuthState(state) {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setPhoneNumber,
  setActiveSlide,
  setLastResendTime,
  resetAuthState,
} = authSlice.actions;

export default authSlice.reducer;