const BASE_PATHS = {
  PROPERTY: 'stays',
  CAR: 'cars',
  TOUR: 'activities',
};

export const ROUTES_CONSTANTS = {
  NOT_FOUND: {path: '/*'},

  AUTH: {
    path: `/auth`,
    link: () => `/auth`,
  },

  //home-pages
  HOME: {
    path: `/`,
    link: () => `/`,
  },
  PROPERTY: {
    path: `/${BASE_PATHS.PROPERTY}`,
    link: () => `/${BASE_PATHS.PROPERTY}`,
  },
  CAR: {
    path: `/${BASE_PATHS.CAR}`,
    link: () => `/${BASE_PATHS.CAR}`,
  },
  TOUR: {
    path: `/${BASE_PATHS.TOUR}`,
    link: () => `/${BASE_PATHS.TOUR}`,
  },

  //search-pages
  PROPERTY_SEARCH: {
    path: `/${BASE_PATHS.PROPERTY}/search`,
    link: () => `/${BASE_PATHS.PROPERTY}/search`,
  },
  CAR_SEARCH: {
    path: `/${BASE_PATHS.CAR}/search`,
    link: () => `/${BASE_PATHS.CAR}/search`,
  },
  TOUR_SEARCH: {
    path: `/${BASE_PATHS.TOUR}/search`,
    link: () => `/${BASE_PATHS.TOUR}/search`,
  },

  //details-pages
  PROPERTY_DETAILS: {
    path: `/${BASE_PATHS.PROPERTY}/:slug`,
    link: (slug) => `/${BASE_PATHS.PROPERTY}/${slug}`,
  },
  CAR_DETAILS: {
    path: `/${BASE_PATHS.CAR}/:slug`,
    link: (slug) => `/${BASE_PATHS.CAR}/${slug}`,
  },
  TOUR_DETAILS: {
    path: `/${BASE_PATHS.TOUR}/:slug`,
    link: (slug) => `/${BASE_PATHS.TOUR}/${slug}`,
  },

  //booking-pages
  PROPERTY_BOOKING: {
    path: `/${BASE_PATHS.PROPERTY}/booking/:slug`,
    link: (slug) => `/${BASE_PATHS.PROPERTY}/booking/${slug}`,
  },
  CAR_BOOKING: {
    path: `/${BASE_PATHS.CAR}/booking/:slug`,
    link: (slug) => `/${BASE_PATHS.CAR}/booking/${slug}`,
  },
  TOUR_BOOKING: {
    path: `/${BASE_PATHS.TOUR}/booking/:slug`,
    link: (slug) => `/${BASE_PATHS.TOUR}/booking/${slug}`,
  },
  BOOKING_DETAILS: {
    path: `/booking-details/:bookingId/:categoryId`,
    link: (bookingId, categoryId) => `/booking-details/${bookingId}/${categoryId}`,
  },

  //profile-pages
  PROFILE: {
    path: `/profile`,
    link: () => `/profile`,
  },
  PROFILE_BOOKINGS: {
    path: `/bookings`,
    link: () => `/profile/bookings`,
  },
  PROFILE_NOTIFICATIONS: {
    path: `/notifications`,
    link: () => `/profile/notifications`,
  },
  PROFILE_CHAT: {
    path: `/chat`,
    link: () => `/profile/chat`,
  },
  PROFILE_REVIEWS: {
    path: `/reviews`,
    link: () => `/profile/reviews`,
  },
  PROFILE_EDIT: {
    path: `/edit`,
    link: () => `/profile/edit`,
  },

  //rest-pages
  ABOUT: {
    path: `/about`,
    link: () => `/about`,
  },
  TERMS_AND_CONDITIONS: {
    path: `/terms-and-conditions`,
    link: () => `/terms-and-conditions`,
  },
  ABOUT_US: {
    path: `/about-us`,
    link: () => `/about-us`,
  },
};