import {useNavigate} from "react-router-dom";
import {Fragment, memo, useCallback, useMemo} from "react";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MobileNavItemHoc} from "./MobileNavItem/MobileNavItemHoc";
import {SVG_ICON} from "../common/svg/svg-icon";
import {CONSTANTS} from "../../constants/constants";
import {mobileNavConfig} from "./mobileNavConfig";
import {ImageContainer} from "../ImageContainer/ImageContainer";
import styles from './MobileNav.module.css';

const ACTIVE_COLOR = '#E89732';
const INACTIVE_COLOR = '#7c7c7c';

export const MobileNav = memo(({pathname}) => {

  const {t} = useTranslation('common');
  const navigate = useNavigate();

  const {isAuth, language} = useSelector(state => state?.user, shallowEqual);
  const imageProfile = useSelector(state => state?.user?.dataPeople?.image, shallowEqual);

  const tabs = useMemo(() => {
    const authTabs = isAuth
      ? [{
        title: 'account',
        link: CONSTANTS?.ROUTES?.PROFILE?.link(),
        path: CONSTANTS?.ROUTES?.PROFILE?.path,
        isImage: true,
        image: imageProfile || null,
      }]
      : [{
        title: 'signIn',
        link: CONSTANTS?.ROUTES?.AUTH?.link(),
        path: CONSTANTS?.ROUTES?.AUTH?.path,
        icon: SVG_ICON?.LOGIN,
      }];

    return [...mobileNavConfig, ...authTabs];
  }, [isAuth, imageProfile]);

  const activeTabIndex = useMemo(() => tabs.findIndex(tab => tab?.path === pathname), [tabs, pathname]);

  const onSetItem = useCallback((link) => navigate(link), [navigate]);

  const transform = useMemo(
    () => ((language?.isRTL) ? (((tabs?.length - 1) - activeTabIndex) * 100) : activeTabIndex * 100),
    [language, activeTabIndex, tabs]
  );

  return (
    <nav
      className={styles.wrapper}
      style={{gridTemplateColumns: `repeat(${tabs?.length ?? 4}, 1fr)`}}
    >
      {(pathname !== CONSTANTS?.ROUTES?.HOME?.path) && (
        <div
          className={`${styles.long}`}
          style={{
            left: 0,
            transform: `translateX(${transform}%)`,
            width: `${100 / tabs.length}%`
          }}
        />
      )}
      {tabs?.map((item, index) => {
        const isActive = (pathname === item?.path);
        const activeColor = (isActive ? ACTIVE_COLOR : INACTIVE_COLOR);
        return (
          <Fragment key={index}>
            <MobileNavItemHoc
              callback={() => onSetItem(item?.link)}
              className={styles.item}
            >
              {(item?.isImage && !item?.icon) && (
                <div
                  className={`
                    ${styles.image} 
                    ${item.image ? '' : 'loading-fon'} 
                    ${isActive ? styles['image-active'] : ''}
                  `}
                >
                  <ImageContainer id={item.image}/>
                </div>
              )}
              {(!item?.isImage && !!item?.icon) && (item?.icon(activeColor))}
              <p style={{color: activeColor}}>{t(item?.title)}</p>
            </MobileNavItemHoc>
          </Fragment>
        )
      })}
    </nav>
  )
});