import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['common'],
    defaultNS: 'common',
    partialBundledLanguages: true,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
    pluralSeparator: '_',
    cache: {
      enabled: true,
      version: 'v1'
    }
  }).then(() => null);

export default i18n;